import {request} from '@/utils/request'

// 企业列表
export function getCompany(data) {
  return request('GET','/shop/contract/getCorporateList', data, true)
}
// 添加
export function postContract(data) {
  return request('POST','/shop/contract', data, true)
}
// 编辑
export function putContract(id, data) {
  return request('PUT','/shop/contract/' + id, data, true)
}
// 删除
export function delContract(id) {
  return request('DELETE','/shop/contract/' + id, '', true)
}
// 列表-分页
export function getContract(data) {
  return request('GET','/shop/contract/page', data, true)
}
// 列表-不分页
export function getContractList(data) {
  return request('GET','/shop/contract', data, true)
}
