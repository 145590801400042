<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">
            <userMenu type="5" />

            <div class="shop_order_list">
                <div class="shop_address_title">
                    <h1 class="title">我的合同</h1>
                </div>

                <div class="shop_order_search mt32">
                    <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-width="102px">
                        <el-form-item label="合同有效期：" prop="date" style="width: 38%">
                            <el-date-picker
                                    v-model="listQuery.date"
                                    type="daterange"
                                    range-separator="~"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="企业/店铺名称：" prop="name" style="width: 38%">
                            <el-input v-model="listQuery.name" placeholder="请输入企业/店铺名称" @keyup.enter.native="toSearchList"></el-input>
                        </el-form-item>

                        <el-form-item class="last" style="width: 24%">
                            <el-button type="primary" :loading="isSearchLoading" @click="toSearchList">查 询</el-button>
                            <el-button @click="$refs['listQuery'].resetFields()">重 置</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="data_group mt18">
                    <el-button type="primary" size="small" icon="el-icon-plus" @click="isPopupAdd = true">新增合同</el-button>
                </div>

                <div class="data_table mt24">
                    <el-table :data="listData" style="width: 100%" v-loading="isRequestLoading">
                        <el-table-column type="index" width="50" align="center"></el-table-column>
                        <el-table-column prop="companyName" label="企业名称" width="200px"></el-table-column>
                        <el-table-column prop="number" label="合同编号" width="200px"></el-table-column>
                        <el-table-column label="有效期" width="200">
                            <template slot-scope="scope">
                                {{scope.row.start}} - {{scope.row.end}}
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="100" align="center">
                            <template slot-scope="scope">
                                <div class="status info" v-if="scope.row.status === 0">未生效</div>
                                <div class="status green" v-if="scope.row.status === 1">已生效</div>
                                <div class="status red" v-if="scope.row.status === -1">已过期</div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="操作" align="center" width="180">
                            <template slot-scope="scope">
                                <el-button type="text" @click="toInfo(scope.row)">查看</el-button>
                                <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
                                <el-button type="text" @click="delData(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>


                <pagination :size="listQuery.size" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange" />
            </div>
        </div>

        <addContract :isPopupAdd="isPopupAdd" :companyList="companyList" @toAddSuccess="getList" @close="isPopupAdd = false" />

        <el-dialog class="popup_dialog" title="编辑合同" :visible.sync="isPopupEdit" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="600px">
            <el-form ref="formEdit" :model="formEdit" :rules="rules" label-position="top" size="small">
                <el-form-item label="企业名称：" prop="companyId">
                    <el-select v-model="formEdit.companyId" placeholder="请选择企业名称" filterable class="fullInput">
                        <el-option v-for="(item, index) in companyList" :key="index" :label="item.corporateName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="合同编号：" prop="number" class="half mrb4">
                    <el-input v-model="formEdit.number" placeholder="请输入合同编号" class="fullInput"></el-input>
                </el-form-item>
                <el-form-item label="合同有效期：" prop="date" class="half">
                    <el-date-picker
                            v-model="formEdit.date"
                            type="daterange"
                            range-separator="~"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            value-format="yyyy-MM-dd" style="width: 220px;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="类型：" prop="type">
                    <el-radio-group v-model="formEdit.type" @change="typeEditChange">
                        <el-radio :label="1">图片</el-radio>
                        <el-radio :label="2">附件</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="上传图片：" prop="image" v-if="formEdit.type === 1 && isPopupEdit">
                    <el-upload
                            ref="uploadEditImg"
                            :action="uploadURL"
                            :before-upload="beforeUploadImage"
                            :on-success="imageUploadEdit"
                            :on-remove="imageRemoveEdit"
                            list-type="picture-card"
                            :file-list="imageFileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
                </el-form-item>

                <el-form-item label="上传附件：" prop="image" v-if="formEdit.type === 2 && isPopupEdit">
                    <el-upload
                            ref="uploadEditFile"
                            class="upload-demo"
                            :action="uploadURL"
                            :before-upload="beforeUploadFile"
                            :on-success="imageUploadEdit"
                            :on-remove="imageRemoveEdit"
                            :file-list="imageFileList">
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                    <div class="el-upload__tip">上传附件大小不超过10M</div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :loading="submitLoading" @click="submitFormEdit('formEdit')">保 存</el-button>
                    <el-button type="default" @click="isPopupEdit = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog class="popup_dialog" title="查看合同" :visible.sync="isPopupInfo" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="740px">
            <el-table ref="goodsFormatData" :data="[listInfo]" style="width: 100%">
                <el-table-column prop="companyName" label="企业名称" width="200px"></el-table-column>
                <el-table-column prop="number" label="合同编号" width="200px"></el-table-column>
                <el-table-column label="有效期" width="180">
                    <template slot-scope="scope">
                        {{scope.row.start}} - {{scope.row.end}}
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="100" align="center">
                    <template slot-scope="scope">
                        <div class="status info" v-if="scope.row.status === 0">未生效</div>
                        <div class="status green" v-if="scope.row.status === 1">已生效</div>
                        <div class="status red" v-if="scope.row.status === -1">已过期</div>
                    </template>
                </el-table-column>
            </el-table>

            <div class="popupContractImage" v-if="listInfo.type === 1">
                <el-image v-for="(item, index) in listInfo.image" :key="index"
                        style="width: 100px; height: 100px"
                        :src="imageURL + item.url"
                          @click="toShowViewer(index)">
                </el-image>
            </div>

            <div class="file-list mt20" style="line-height: 25px;" v-if="listInfo.type === 2">
                <div v-for="(item, index) in listInfo.image" :key="index">
                    <i class="el-icon-document"></i>
                    <span class="ml16">{{item.name}}</span>
                    <a href="javascript:void(0)" @click="download(imageURL + item.url, item.name)"><i class="el-icon-download ml16" style="color: #5DAD03; font-weight: bolder;"></i></a>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="isPopupInfo = false" size="small">关 闭</el-button>
            </div>
        </el-dialog>

        <imageViewer v-if="showViewer" :initialIndex="initialIndex" :on-close="closeViewer" :url-list="getPopupImage(listInfo.image)" />
    </div>
</template>

<script>
  import {imageURL, uploadURL} from "../../../utils/config";
  import {beforeUploadImage, beforeUploadFile} from '../../../utils/method'
  import {getCompany, getContract, putContract, delContract} from '../../../api/shop/contract'
  import {download} from '../../../utils/method';
  const userMenu = () => import('../../../components/layout/shop/userMenu')
  const pagination = () => import('../../../components/pagination/shop')
  const addContract = () => import('../../../components/layout/shop/addContract')
  const imageViewer = () => import('../../../components/imageViewer')
  export default {
    name: "shopContract",
    data () {
      return {
        listQuery: {
          name: '',
          date: [],
          page: 1,
          size: 10
        },
        pages: {
          total: 0,
          pages: 0
        },
        listData: [],
        isRequestLoading: false,
        isSearchLoading: false,
        imageURL: imageURL,
        uploadURL: uploadURL,
        isPopupAdd: false,
        submitLoading: false,
        rules: {
          companyId: [
            {required: true, message: '请选择企业名称', trigger: 'change'}
          ],
          number: [
            {required: true, message: '请输入合同编号', trigger: 'blur'}
          ],
          date: [
            {required: true, message: '请选择合同有效期', trigger: 'change'}
          ],
          image: [
            {required: true, message: '请上传合同附件', trigger: 'change'}
          ],
        },
        companyList: [],
        isPopupEdit: false,
        editId: null,
        formEdit: {
          companyId: '',
          number: '',
          date: [],
          type: 1,
          image: []
        },
        imageFileList: [],
        listInfo: {},
        isPopupInfo: false,
        download: download,
        showViewer: false,
        prevOverflow: '',
        initialIndex: 0,
        beforeUploadImage: beforeUploadImage,
        beforeUploadFile: beforeUploadFile
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getList()
      this.getCompany()
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        let start = ''
        let end = ''
        if (this.listQuery.date.length > 0) {
          start = this.listQuery.date[0] + ' 00:00:00'
          end = this.listQuery.date[1] + ' 23:59:59'
        }
        getContract({
          name: this.listQuery.name,
          start: start,
          end: end,
          page: this.listQuery.page,
          size: this.listQuery.size
        }).then(res => {
          this.isRequestLoading = false
          this.isSearchLoading = false
          if (res.code === 0) {
            this.listData = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
          this.isSearchLoading = false
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.size = e.size
        this.getList()
      },
      toSearchList () {
        this.listQuery.page = 1
        this.isSearchLoading = true
        this.getList()
      },
      getCompany () {
        getCompany().then(res => {
          if (res.code === 0) {
            this.companyList = res.data
          }
        })
      },
      toEdit (item) {
        this.editId = item.id
        this.formEdit.companyId = item.companyId
        this.formEdit.number = item.number
        this.formEdit.date = [item.start, item.end]
        this.formEdit.type = item.type
        Object.assign(this.formEdit.image, item.image)
        let imageFileList = []
        item.image.forEach((item) =>{
          imageFileList.push({
            name: item.name,
            url: this.imageURL + item.url,
            response: {
              data: item.url
            }
          })
        })
        this.imageFileList = imageFileList
        this.isPopupEdit = true
      },
      imageUploadEdit (res, file) {
        if (res.code === 0) {
          this.formEdit.image.push({
            url: res.data,
            name: file.name
          })
        }
      },
      imageRemoveEdit (file, fileList) {
        let data = []
        fileList.forEach(item => {
          data.push({
            url: item.response.data,
            name: item.name
          })
        })
        this.formEdit.image = data
      },
      typeEditChange () {
        this.formEdit.image = []
        this.imageFileList = []
        this.$refs.uploadEditImg.clearFiles()
        this.$refs.uploadEditFile.clearFiles()
      },
      submitFormEdit (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            let companyName = ''
            let start = ''
            let end = ''

            companyName = this.companyList.filter(item => item.id === this.formEdit.companyId)[0].corporateName

            if (this.formEdit.date.length === 2) {
              start = this.formEdit.date[0]
              end = this.formEdit.date[1]
            }

            putContract(this.editId, {
              data: {
                companyId: this.formEdit.companyId,
                companyName: companyName,
                start: start,
                end: end,
                number: this.formEdit.number,
                type: this.formEdit.type,
                image: this.formEdit.image,
              }
            }).then(res => {
              this.submitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '合同编辑成功',
                  type: 'success',
                  showClose: true,
                });
                this.isPopupEdit = false
                this.getList()
              }
            }).catch(() => {
              this.submitLoading = false
            })
          }
        })
      },
      toInfo (item) {
        this.listInfo = item
        this.isPopupInfo = true
      },
      delData (id) {
        this.$confirm('此操作将永久删除该合同, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          delContract(id).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '合同删除成功',
                type: 'success',
                showClose: true,
              });
              this.getList()
            }
          })
        }).catch(() => {});
      },
      getPopupImage (list) {
        let image = []
        list.forEach(item => {
          image.push(this.imageURL + item.url)
        })
        return image
      },
      toShowViewer (index) {
        this.initialIndex = index
        this.prevOverflow = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        this.showViewer = true
      },
      closeViewer () {
        document.body.style.overflow = this.prevOverflow;
        this.showViewer = false
      },
    },
    components: {
      userMenu, pagination, addContract, imageViewer
    }
  }
</script>
